<template>
  <div class="loan_item">
    <div class="inner inner0_5">
      <img src="@/assets/images/loanImg/loan_inner0.5.png">
      <div>
        <h1>What is EasyCash?</h1>
        <p>EasyCash is an online loan application. You can get safe and flexible loans through EasyCash.
          You can choose a variety of methods to receive the loan amount. You can also pay your loan
          through multiple channels and there are no hidden charges. Your information is secure with us
          and used for verification only. We will determine the loan amount through the risk assessment
          of your information and credit evaluation.
        </p>
      </div>
      <img class="wh" src="@/assets/images/loanImg/loan_wh.png">
    </div>
    <div class="inner inner2">
      <h1>Our Core Values</h1>
      <div class="items">
        <div class="right_item">
          <div class="item_info">
            <div class="info_tips">
              <div class="items">
                <img class="info_img1" src="@/assets/images/loanImg/app-icon-1.png" alt="">
                <div>
                  <h3>open</h3>
                  <p>Respect and tolerance, empathy</p>
                </div>
              </div>
              <div class="items">
                <img class="info_img1" src="@/assets/images/loanImg/app-icon-2.png" alt="">
                <div>
                  <h3>cooperation</h3>
                  <p>Collaborate with each other and continue to evolve</p>
                </div>
              </div>
              <div class="items">
                <img class="info_img1" src="@/assets/images/loanImg/app-icon-3.png" alt="">
                <div>
                  <h3>create</h3>
                  <p>Beyond innovation, explore the future</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="left_item">
          <img src="@/assets/images/loanImg/icon_people.png" alt="">
        </div>
      </div>
    </div>
    <div class="inner inner6">
      <div class="inner6_item content reverse">
        <div class="inner6_item_left">
          <div>
            <h1 class="inner6_item_left_title">Mission</h1>
            <div class="inner6_item_left_list">
              <span>
                Provide more flexible, reliable, convenient and efficient financial
                services for low-income and disadvantaged groups with innovative
                technology
              </span>
            </div>
          </div>
        </div>
        <div class="inner6_item_right"><img src="@/assets/images/loanImg/ng1.png" alt=""></div>
      </div>
      <div class="inner6_item content">
        <div class="inner6_item_left">
          <div>
            <h1 class="inner6_item_left_title">Vision</h1>
            <div class="inner6_item_left_list">
              <span>
                To solve the problem of money use and improve the quality of
                life for everyone, we are committed to providing the best financial
                technology services to society.
              </span>
            </div>
          </div>
        </div>
        <div class="inner6_item_right"><img src="@/assets/images/loanImg/ng2.png" alt=""></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.loan_item{
  .inner{
    min-width: 1100px;
    margin: 0 auto;
    overflow: hidden;
    h3{
      font-size: 18px;
      color: #3E1FA5;
    }
    .content{
      padding: 0px calc((100% - 1100px) / 2) 0;
      display: flex;
    }
    .left_cell{
      float: left;
      padding: 60px 0;
      text-align: left;
      h1{
        font-size: 40px;
        margin-bottom: 30px;
      }
      p{
        font-size: 12px;
        line-height: 30px;
        color: #999999;
      }
      .cell_item{
        width: 267px;
        height: 140px;
        margin-top: 18px;
        border-bottom: 1px solid #6E60F9;
      }
    }
  }
  .inner0_5{
    // background: url('~@/assets/images/loanImg/abouts_bg.png') no-repeat;
    // background-size: 100% 100%;
    background: #3E1FA5;
    padding: 50px calc((100% - 1500px) / 2)  0 calc((100% - 1800px) / 2);
    display: flex;
    p{
      font-size: 15px;
      font-family: Arial-Regular, Arial;
      color: #FFFFFF;
      width:calc(38%);
      line-height: 30px;
    }
    div{
      h1{
        color: white;
        font-size: 40px;
        line-height: 150px;
        margin-top: 60px;
      }
      p{
        width: 100%;
        background: #644FFA;
        padding: 25px 25px;
        line-height: 25px
      };
    }
  }
  .inner2{
    // height: 420px;
    background:#FFFFFF;
    text-align: center;
    padding:50px calc((100% - 1100px) / 2);
    h1{
      margin-bottom: 60px;
      font-size: 36px;
    }
    .items{
      display: flex;
      }
    .left_item{
      flex: 1;
      text-align: end;
      img{
        width: 480px;
        height: 100%;
        // margin-right: 50px;
      }
    }
    .right_item{
      flex: 1;
      margin-right:30px;
      text-align: left;
      line-height: 30px;
      p{
        color:#3E1FA5;
        font-size: 20px;
        font-weight: bold;
      }
      .item_tips{
        display: flex;
        width: 460px;
        p{
          width: 70px;
          font-size: 12px;
          color: #777777;
          text-align: left;
        }
      }
      .item_info{
        // margin: 20px 0;
        height: 100%;
        display: flex;
        .info_img1{
          width: 76px;
        }
        .info_tips{
          width: 90%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .items{
            flex: 1;
            display: flex;
            background: #F3F0FA !important;
            padding: 0px 60px 0px 10px;
            border-radius: 10px;
            align-items: center;
            margin-bottom: 30px;
            img{
              margin-right: 10px;
            }
          }
          .items:nth-last-child(1){
            margin-bottom: 0px !important;
          }
          p{
            font-size: 12px;
            color: #3E1FA5;
            line-height: 20px;
          }
        }
      }
    }
  }
  .inner6{
    .inner6_item{
      display: flex;
      align-items: center;
      margin-bottom: 50px;
      .inner6_item_left{
        z-index: 2;
        border-radius: 10px;
        background: #2A1572;
        color: #ffffff90;
        padding: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        box-sizing: border-box;
        width: 50%;
        line-height: 20px;
        h1{
          text-align:left;
          margin-bottom: 26px;
          width: auto;
          color: #ffffff;
        }
        .inner6_item_left_title{
          text-align: center;
        }
        .inner6_item_left_list{
          display: flex;
          align-items: center;
          margin-bottom: 20px;
          img{
            width: 34px;
            height: 34px;
            margin-right: 18px;
          }
        }
      }
      .inner6_item_right{
        width: 60%;
        z-index: 1;
        margin-left: -40px;
        img{
          padding: 2px;
          // border: 2px dashed rgb(206, 206, 206);
          width: 100%;
        }
      }
    }
    .reverse{
      flex-direction:  row-reverse;
      .inner6_item_right{
        margin-left: 0px;
      }
      .inner6_item_left{
        margin-left: -40px;

      }
    }
  }
}
@media only screen and (max-width: 665px){
  .loan_item{
    .inner{
      min-width: calc(100vw) !important;
    }
    .inner0_5{
      background: #171055;
      color: white;
      padding: 50px 20px 0;
      flex-direction: column;
      p{
        font-size: 12px;
        width:100%;
        line-height: 30px;
      }
      div{
        h1{
          text-align: center;
          margin-top: 20px;
          font-size: 24px;
          line-height: 50px;
        }
      }
      .wh{
        width: calc(100% - 150px);
        margin: 0 auto;
        padding-left: 50px;
      }
    }
    .inner1{
      h1{
        margin-top: 30px !important;
      }
      h4{
        padding: 0 20px;
      }
      .inner1_text{
        padding: 0 !important;
      }
      .content{
        padding: 0 20px !important;
        display: flex;
        flex-direction: column;
        .left_cell{
          padding: 0;
        }
        .cell_item{
          width: 100% !important;
          height: 100px;
        }
        img{
          margin-top: 0px;
          width: 300px !important;
          height: 300px !important;
          margin: 0 auto ;
        }
      }
    }
    .inner2{
      h1{
        margin-bottom: 20px !important;
      }
      .right_item{
        margin-right: 0;
        text-align: center;
      }
      .items{
        padding: 20px !important;
        display: flex;
        flex-direction: column;
        .info_img1{
          margin-right: 0 !important;
        }
      }
      .info_tips{
        width: 100% !important;
      }
      .left_item{
        margin-top: 20px;
        width: 100%;
        img{
          width: 100%;
        }
      }
    }
    .inner6{
      padding: 0 20px;
      .inner6_item{
        flex-direction: column;
        .inner6_item_left{
          margin-left: 0;
          width: 100%;
          padding: 50px 20px;
        }
        .inner6_item_right{
          width: 80%;
          margin-top: -20px;
          margin-left: 0;
        }
      }
    }
  }
}
</style>
